import axios from "@/helpers/axios";

import store from '@/store';

export default {
    getAll: async () => {
        const response = await axios.get('/mes_notes');
        if(response && response.data) {
            store.dispatch('saveNotes', response.data);
            return response.data;
        } else {
            return [];
        }
    },
}