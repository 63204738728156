import VueRouter from "vue-router";
import routes from "./routes";
import store from "@/store";
import userService from "@/services/user";

// configure router
const router = new VueRouter({
  routes, // short for routes: routes
  linkExactActiveClass: "active",
  mode: "history",
  scrollBehavior: (to) => {
    if (to.hash) {
      return { selector: to.hash };
    } else {
      return { x: 0, y: 0 };
    }
  },
});

const anonymousPages = ["login"];

router.beforeEach((to, from, next) => {
  const publicRessource = anonymousPages.includes(to.name);
  const isLoggedIn = store.getters.isLoggedIn;

  if (to.path == "/logout") {
    userService.logout();
  }

  if (!publicRessource && !isLoggedIn) next({ name: "login" });
  else if (publicRessource && isLoggedIn) next({ name: "calendar" });
  else next();
});

export default router;
