<template>
  <div></div>
</template>
<script>
import config from "@/config";

export default {
  components: {
  },
};
</script>
<style>
</style>
