import Vue from "vue";
import VueRouter from "vue-router";
import RouterPrefetch from "vue-router-prefetch";
import App from "./App";
import store from "./store";
import router from "./router/index";

import BlackDashboard from "./plugins/blackDashboard";
import i18n from "./i18n";
import "./registerServiceWorker";
Vue.use(BlackDashboard);
Vue.use(VueRouter);
Vue.use(RouterPrefetch);

// Optional
import Vue2TouchEvents from "vue2-touch-events";
Vue.use(Vue2TouchEvents);

import VCalendar from "v-calendar";
Vue.use(VCalendar);

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);

/* eslint-disable no-new */
new Vue({
  router,
  i18n,
  store,
  render: (h) => h(App),
}).$mount("#app");
