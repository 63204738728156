import axios from "@/helpers/axios";
import store from "@/store";

export default {
  get: async () => {
    const response = await axios.get("/configuration");
    if (response && response.data) {
      store.dispatch("setProfile", response.data.individu);
    }
  },
  remove: () => {
    store.dispatch("removeProfile");
  },
};
