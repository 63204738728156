import router from "@/router";
import { setBearer } from "@/helpers/axios";

export default {
  state: () => ({
    token: null,
    discord: "",
    notification: "",
    maxNewGrades: null,
  }),
  mutations: {
    modifyToken(state, newToken) {
      state.token = newToken;
      setBearer();
    },
    modifyDiscord(state, newDiscord) {
      state.discord = newDiscord;
    },
    modifyNotification(state, newNotification) {
      state.notification = newNotification;
    },
    modifyMaxNewGrades(state, number) {
      state.maxNewGrades = number;
    },
  },
  actions: {
    login(context, payload) {
      context.commit("modifyToken", payload.bearer.normal);
      // context.commit("modifyDiscord", payload.discord);
      // context.commit("modifyNotification", payload.notification);
      router.push({ name: "notes" });
    },
    logout(context) {
      context.commit("modifyToken", null);
      context.commit("modifyDiscord", "");
      context.commit("modifyNotification", "");
      context.commit("modifyMaxNewGrades", null);
      router.push({ name: "login" });
    },
  },
  getters: {
    isLoggedIn: (state) => {
      return state.token != null;
    },
    token: (state) => {
      return state.token;
    },
    hasNotificationsConfigured: (state) => {
      return (
        (state.discord && state.discord != "") ||
        (state.notification && state.notification != "")
      );
    },
    hasMaxGradesConfigured: (state) => {
      return state.maxNewGrades && Number.isInteger(state.maxNewGrades);
    },
  },
};
