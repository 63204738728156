import axios from "axios";
import store from "@/store";

const instance = axios.create({
  baseURL: "https://mycpe.cpe.fr/mobile/",
  timeout: 3000,
  headers: {
    Authorization: store.getters.token ? `Bearer ${store.getters.token}` : ''
  }
});
instance.interceptors.response.use(undefined, function (error) {
  if(error.response.status === 401) {
    store.dispatch("logout");
    return Promise.reject(error);
  }
});

export default instance;

export function setBearer(){
  const token = store.getters.token;
  if(token) {
    instance.defaults.headers.Authorization = `Bearer ${token}`;
  } else {
    delete instance.defaults.headers.Authorization;
  }
};