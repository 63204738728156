import axios from "@/helpers/axios";
import date from "@/helpers/date";
import store from "@/store";

const service = {
  getEventsByMonth: async (month = new Date()) => {
    const events = store.getters.getEventsByMonth(month);

    // Return already loaded months
    if(events) return events;

    // Load month if not already stored
    const [firstDate, lastDate] = date.getFirstAndLastDayOfMonth(month);

    const response = await axios.get('/mon_planning', { 
      params: { 
        date_debut: date.formatToApi(firstDate), 
        date_fin: date.formatToApi(lastDate)
      } 
    });

    if (response && response.data) {
      const formatted = [];
      response.data.forEach(event => {
        if (!event.is_empty && !event.is_break) {
          event.start = event.date_debut;
          event.end = event.date_fin;
          formatted.push(event)
        }
      })
      store.dispatch("updateMonth", {month, events: formatted});
      return formatted;
    } else {
      return [];
    }
  }
};

export default service;
