<template>
  <div class="wrapper">
    <side-bar>
      <template slot="links">
        <sidebar-link to="/logout" :name="$t('user.logout')" icon="tim-icons icon-double-left"/>
        <sidebar-link to="/notes" :name="$t('notes.notes')" icon="tim-icons icon-bullet-list-67"/>
        <sidebar-link to="/calendar" :name="$t('calendar.calendar')" icon="tim-icons icon-calendar-60"/>
        <sidebar-link to="/settings" :name="$t('settings.settings')" icon="tim-icons icon-settings"/>
      </template>
    </side-bar>
    <div class="main-panel">
      <top-navbar></top-navbar>

      <dashboard-content @click.native="toggleSidebar">

      </dashboard-content>

      <content-footer></content-footer>
    </div>
  </div>
</template>
<style lang="scss">
</style>
<script>
import TopNavbar from "./TopNavbar.vue";
import ContentFooter from "./ContentFooter.vue";
import DashboardContent from "./Content.vue";
import MobileMenu from "./MobileMenu";

export default {
  components: {
    TopNavbar,
    ContentFooter,
    DashboardContent,
    MobileMenu
  },
  methods: {
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false);
      }
    },
  }
};
</script>
