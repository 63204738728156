import calendarService from "@/services/calendar"

export default {
  state: () => ({
    months: {}
  }),
  mutations: {
    updateEvents(state, {month, events}) {
      state.months[`${month.getFullYear()}-${month.getMonth()}`] = events;
    },
  },
  actions: {
    updateMonth(context, {month, events}) {
      context.commit("updateEvents", {month, events});
    }
  },
  getters: {
    getEventsByMonth: (state) => (month = new Date()) => {
      return state.months[`${month.getFullYear()}-${month.getMonth()}`];
    }
  },
};
