import { App } from '@capacitor/app';
import noteService from "@/services/note";

const time = (s) => {
  return s * 1000;
}

var backgroundTask = null;

const LaunchBackgroundFetchNotes = () => {
  backgroundTask = setInterval(async () => {
    await noteService.getAll();
  }, time(60));
}

App.addListener('appStateChange', ({ isActive }) => {
  console.log('App state changed. Is active?', isActive);

  LaunchBackgroundFetchNotes();
});

LaunchBackgroundFetchNotes();

export default {};