import DashboardLayout from "@/layout/dashboard/DashboardLayout.vue";
// GeneralViews
import NotFound from "@/pages/NotFound.vue";

// Admin pages
const Notes = () => import(/* webpackChunkName: "notes" */"@/pages/Notes.vue");
const Settings = () => import(/* webpackChunkName: "settings" */"@/pages/Settings.vue");
const Calendar = () => import(/* webpackChunkName: "calendar" */"@/pages/Calendar.vue");
const Login = () => import(/* webpackChunkName: "login" */"@/pages/Login.vue");

const routes = [
  {
    path: "/",
    component: DashboardLayout,
    redirect: "/notes",
    children: [
      {
        path: "notes",
        name: "notes",
        component: Notes
      },
      {
        path: "settings",
        name: "settings",
        component: Settings
      },
      {
        path: "calendar",
        name: "calendar",
        component: Calendar
      },
      {
        path: "login",
        name: "login",
        component: Login
      },
    ]
  },
  { path: "*", component: NotFound },
];

/**
 * Asynchronously load view (Webpack Lazy loading compatible)
 * The specified component must be inside the Views folder
 * @param  {string} name  the filename (basename) of the view to load.
function view(name) {
   var res= require('../components/Dashboard/Views/' + name + '.vue');
   return res;
};**/

export default routes;
