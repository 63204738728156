import router from "@/router";
import { setBearer } from "@/helpers/axios";

export default {
  state: () => ({
    id: null,
    name: "",
    firstname: "",
    alternant: null,
    intervenant: null,
  }),
  mutations: {
    modifyProfile(state, newProfile) {
      state.id = newProfile?.individu_id;
      state.name = newProfile?.nom;
      state.firstname = newProfile?.prenom;
      state.apprenant = newProfile?.est_apprenant;
      state.intervenant = newProfile?.est_intervenant;
    },
  },
  actions: {
    setProfile(context, payload) {
      context.commit("modifyProfile", payload);
    },
    removeProfile(context) {
      context.commit("modifyProfile", {});
    },
  },
  getters: {
    profile: (state) => {
      return state;
    }
  },
};
