export default {
  state: () => ({
    notes: [],
    newNotes: [],
    newNotesIcon: true,
  }),
  mutations: {
    modifyNotes(state, notes) {
      state.notes = notes || [];
    },
    getNewNotes(state, cours) {
      const sortedNotes = cours.map(item => item.epreuves).flat().sort((x, y) => {
        return new Date(y.date_obtention) - new Date(x.date_obtention);
      });
      state.newNotes = sortedNotes.slice(0, 5);
    },
    modifyNewNotes(state, notes) {
      state.newNotes = notes || [];
    },
    setNewNotesIcon(state, newNotesIcon) {
      state.newNotesIcon = newNotesIcon;
    },
  },
  actions: {
    saveNotes(context, newNotes) {
      context.commit("modifyNotes", newNotes);
      context.commit("getNewNotes", newNotes);
    },
    saveNewNotes(context, newNotes) {
      context.commit("modifyNewNotes", newNotes);
    },
  },
  getters: {
    hasNotes: (state) => {
      return Array.isArray(state.notes) && state.notes.length > 0;
    },
    notes: (state) => {
      return state.notes;
    },
    newNotes: (state) => {
      return state.newNotes;
    },
    newNotesIcon: (state) => {
      return state.newNotesIcon;
    },
  },
};
