import axios from "@/helpers/axios";
import store from "@/store";
import profileService from "./profile";
import noteService from "./note";

export default {
  login: async (mail, password) => {
    const response = await axios.post("/login", {
      login: mail,
      password,
    });
    if (response && response.data) {
      store.dispatch("login", {
        bearer: response.data,
        mail,
        password
      });
      await profileService.get();
      await noteService.getAll();
    }
  },
  logout: () => {
    store.dispatch("logout");
    profileService.remove();
  },
  changeNotifications: async (payload) => {
    try {
      const response = await axios.post(
        "/student/changeNotifications",
        payload
      );
      return response && response.data;
    } catch (error) {
      return {
        error,
      };
    }
  },
};
