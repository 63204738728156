const helper = {
  getFirstAndLastDayOfMonth: (month) => {
    return [new Date(month.getFullYear(), month.getMonth(), 1), new Date(month.getFullYear(), month.getMonth() + 1, 0)];
  },
  getDaysInCurrentWeek(date) {
    const week = [];
    date.setDate(date.getDate() - date.getDay() + 1);
    for (var i = 0; i < 7; i++) {
      week.push(new Date(date));
      date.setDate(date.getDate() + 1);
    }
    return week;
  },
  formatToApi: (date) => {
    const day = ('0' + date.getDate()).slice(-2);
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const year = date.getFullYear();

    return year + '-' + month + '-' + day;
  },
  getDay: (date) => {
    return ('0' + date.getDate()).slice(-2);
  },
  getFirtsAndLastDay: (date) => {
    const week = helper.getDaysInCurrentWeek(date);
    return [week[0], week[week.length - 1]];
  },
  getNextWeek: (date) => {
    return helper.getDaysInCurrentWeek(new Date(date.getTime() + 7 * 24 * 60 * 60 * 1000));
  },
  getPreviousWeek: (date) => {
    return helper.getDaysInCurrentWeek(new Date(date.getTime() - 7 * 24 * 60 * 60 * 1000));
  }
} 
 
export default helper;