import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersistence from 'vuex-persist';

import userModule from './user';
import noteModule from './note';
import profileModule from './profile';
import calendarModule from './calendar';

Vue.use(Vuex);

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  key: "CPE",
  reducer: (state) => ({userModule: state.userModule, noteModule: state.noteModule, profileModule: profileModule}),
});
 

export default new Vuex.Store({
  modules: {
    userModule,
    noteModule,
    profileModule,
    calendarModule
  },
  plugins: [vuexLocal.plugin]
})